@import "src/styles/utils";

.settings {
  display: flex;
  column-gap: 15px;
  margin-bottom: 62px;

  &:first-child {
    margin-bottom: 46px;
  }
}

.item {
  height: 26px;
  padding: 0 17px;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 15px;

  &:hover,
  &.active {
    border-color: #fff;
  }
}

.inputPipe {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 7px 13px;
  outline: none;
  background-color: transparent;
  color: #fff;
}

@include iPadPro {
  .settings {
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .item {
    height: 26px;
    line-height: 26px;
  }

  .link {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .item {
    height: 54px;
  }
}
