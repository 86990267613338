@import "src/styles/utils";

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row-gap: 0;
  grid-column-gap: 10px;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover,
  &.active {
    font-weight: 500;

    .wrapper {
      width: 80px;
      height: 80px;
      border-color: #fff;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;
  height: 70px;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 50%;
  transition: all 0.3s;
  flex: none;
}

.color {
  width: 100%;
  height: 100%;
  background-position: center;
  border-radius: 50%;
  border: none;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

@include iPadPro {
  .circle {
    grid-column-gap: 0;

    &:hover,
    &.active {
      .wrapper {
        width: 86px;
        height: 86px;
      }
    }

    p {
      display: none;
    }
  }

  .wrapper {
    width: 76px;
    height: 76px;
    padding: 8px;
  }
}

@include mobile {
  .circle {
    grid-column-gap: 0;

    &:hover,
    &.active {
      .wrapper {
        width: 48px;
        height: 48px;
      }
    }

    p {
      display: none;
    }
  }

  .wrapper {
    width: 42px;
    height: 42px;
    padding: 6px;
  }
}
