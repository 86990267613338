@font-face {
  font-family: "Georgia";
  src: url("./fonts/Georgia-Regular-font.ttf") format("ttf");
}

@font-face {
  font-family: "Script MT";
  src: url("./fonts/ScriptMTBold.ttf") format("ttf");
}

@font-face {
  font-family: "Eduardian";
  src: url("./fonts/edwardian-script-itc.ttf") format("ttf");
}
