@import "src/styles/utils";

.color {
  //max-width: 300px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 24px;
  margin-bottom: 65px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;

  &:hover,
  &.active {
    font-weight: 500;

    .wrapper {
      border-color: #fff;
    }
  };
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 68px;
  height: 68px;
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 50%;
}

.inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: none;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

@include notebook {
  .color {
    margin-bottom: 20px;
  }
}

@include iPadPro {
  .color {
    justify-content: center;
    max-width: 100%;
    margin-bottom: 0;
    gap: 1px;
    flex-wrap: nowrap;
  }
}

@include mobile {
  .color {
    justify-content: center;
    max-width: 100%;
    margin-bottom: 0;
    gap: 1px;
    flex-wrap: nowrap;
  }
}
