.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  padding-bottom: 90px;

  &__text {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 1150px;
    row-gap: 15px;
    color: #7E7E7E;
    font-weight: 500;
    line-height: 19px;
  }

  &__socials {
    display: flex;
    column-gap: 20px;
  }

  &__socIcon {
    height: 32px;
    width: 32px;
    border: 1px solid #000000;
    border-radius: 50%;

    &:hover {
      border: 1px solid #ffffff;

      circle {
        fill: none;
      }
    }
  }
}