@import "src/styles/utils";

.tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.tab {
  min-height: 43px;
  width: 174px;
  font-size: 16px;
  font-weight: 500;
  color: $white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s linear;
  background-color: transparent;
  border: 1px solid $white;
  border-radius: 20px;
  cursor: pointer;

  &.active,
  &:hover {
    color: #000;
    background-color: #fff;
  }

  &:first-child,
  &:last-child {
    margin-left: 70px;
  }
}

@include iPadPro {
  .tabs {
    flex-direction: row;
    align-items: flex-end;
  }

  .tab {
    font-size: 14px;
    padding: 10px;
    min-height: 36px;
    width: 141px;

    &:first-child,
    &:last-child {
      margin-left: 0;
      margin-bottom: 40px;
    }
  }
}

@include mobile {
  .tab {
    font-size: 10px;
    padding: 6px;
    min-height: 28px;
    width: 100px;

    &:first-child,
    &:last-child {
      margin-left: 0;
      margin-bottom: 45px;
    }

    &:nth-child(2) {
      margin-left: -40px;
      margin-right: 10px;
    }

    &:nth-child(3) {
      margin-left: 10px;
      margin-right: -40px;
    }
  }
}
