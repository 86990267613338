.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 66px);
}

.loader {
  width: auto;
  height: 100px;
  object-fit: contain;
  animation-name: loading;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}

@keyframes loading {
  0% {
    opacity: 1;
    transform: scale(0.8);
  }
  100% {
    opacity: 0.3;
    transform: scale(1);
  }
}
