@import "src/styles/utils";

.color {
  width: 100%;
  margin-top: -15px;
}

.tabs {
  display: flex;
  column-gap: 8px;
  margin-bottom: 34px;
}

.tab {
  height: 26px;
  padding: 0 9px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  border-radius: 15px;
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;

  &.active {
    font-weight: 500;
    border-color: #fff;
  }
}

.hidden {
  display: none;
}



@include iPadPro {
  .tabs {
    justify-content: center;
  }
}
