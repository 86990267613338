.accessories {

  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;

    @media (min-width: 768px) {
      padding: 60px 0;
    }
    @media (min-width: 1440px) {
      flex-direction: row;
      justify-content: space-between;
      padding: 80px 80px 80px 122px;
    }
  }

  &__title {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 40px;

    @media (min-width: 768px) {
      font-size: 36px;
    }
    @media (min-width: 1440px) {
      font-size: 50px;
    }
  }

  &__body {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.productBlock {

  &__wrap {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__img {
    height: 214px;
    width: 214px;
    margin-bottom: 20px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    @media (min-width: 768px) {
      height: 242px;
      width: 242px;
    }
    @media (min-width: 1440px) {
      height: 320px;
      width: 320px;
    }
  }

  &__content {
    margin-bottom: 20px;

    p {
      font-weight: 500;
      font-size: 16px;

      @media (min-width: 768px) {
        font-size: 18px;
      }
      @media (min-width: 1440px) {
        font-size: 20px;
      }
    }
  }

  &__name {
    margin-bottom: 12px;
  }
}

.button {
  height: 40px;
  width: 150px;
  background-color: #23B6E4;
  border-radius: 25px;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0 auto;
  font-weight: 500;
  font-size: 14px;

  @media (min-width: 768px) {
    height: 49px;
    width: 195px;
    font-size: 16px;
  }

  @media (min-width: 1440px) {
    display: none;
  }
}