.popup {
    background: url("../../assets/images/mobile-popup/mobile-popup-bg.png") 50% / cover no-repeat;
    width: 100vw;
    height: 100vh;

    &_wrapper {
        position: relative;
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 1000;
        opacity: 0.9;
    }

    &__body {
        width: 95vw;
        margin: 0 auto;
        padding: 60px;
    }

    &__title {
        font-weight: 500;
        font-size: 30px;
        width: 278px;
        margin: 0 auto;
    }

    &__items {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        height: 60vh;
        justify-content: space-between;
    }
}

.item {
    cursor: pointer;
    margin: 0 auto;
    &__head {
        display: flex;
        align-items: center;
    }
    &__circle {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: relative;
    }

    &__circle img {
        position: absolute;
        left: calc(50% - 2px);
        top: calc(50% - 2px);
    }
    &__circle.active {
        border: 1px solid #60d9ff;
    }
    &__title {
        font-size: 18px;
        font-weight: 500;
        padding-left: 10px;
    }

    &__about {
        font-size: 14px;
        font-weight: 400;
        margin: 10px 0 0 25px;
        width: 253px;
    }
}

.visible__button {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    background-color: #4e4e4e;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1001;
    display: none;
}

@media (min-width: 768px) {
    .item {
        &__about {
            font-size: 17px;
            font-weight: 400;
            width: 290px;
        }

        &__circle {
            width: 19px;
            height: 19px;
        }

        &__title {
            font-size: 21px;
        }
    }

    .popup {
        &__title {
            font-weight: 500;
            font-size: 30px;
            width: 318px;
            margin: 0 auto;
        }
    }
}

@media (max-width: 768px) {
    .visible__button {
        display: block;
    }
}

.unshown {
    position: relative;
    display: none;
}
