@import "src/styles/utils";

.canvas {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  animation-name: appear;
  animation-duration: 5s;
  animation-timing-function: linear;
  background-color: #000;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include mobile {
  .canvas {
    //position: fixed;
  }
}
