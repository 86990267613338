@import "src/styles/utils";
@import "src/components/custom-pipe/custom-pipe-color/CustomPipeColor.module";

.slider {
  display: flex;
  flex-wrap: wrap;
  height: 200px;
}

.insert_colors {
  margin-top: -45px;
  display: flex;
  flex-wrap: wrap;
}

// .slider__wrapper {
//   margin-top: -150px;
// }
// @media (max-width: 1000px) {
//   .slider__wrapper {
//     margin-top: 20px;
//   }

//   .insert_colors {
//     margin-top: 0px;
//   }
// }

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;

  img {
    object-fit: contain;
    width: 100px;
    height: 100px;
  }
}

@include iPadPro {
  .slider {
    height: auto;
    max-width: 100%;
  }

  .insert_colors {
    margin-top: 20px;
  }
}

@include mobile {
  .slider {
    height: auto;
    max-width: 100%;
  }

  .insert_colors {
    margin-top: 10px;
  }

  .item {
    width: 70px;
    height: 70px;

    img {
      width: 70px;
      height: 70px;
    }
  }
}
