@import "../../styles/utils";

.сheckout {
    width: 100vw;
    height: 100vh;
    background-color: $black;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    overflow: hidden;
    position: relative;

    &__close {
        position: absolute;
        z-index: 1;
        top: 30px;
        right: 30px;
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L11 11' stroke='white' stroke-width='1.11111' stroke-linecap='round'/%3E%3Cpath d='M1 11L11 1' stroke='white' stroke-width='1.11111' stroke-linecap='round'/%3E%3C/svg%3E%0A");

        @media (min-width: 768px) {
            width: 20px;
            height: 20px;
            top: 40px;
            right: 40px;
        }

        @media (min-width: 1024px) {
            width: 10px;
            height: 10px;
            top: 30px;
            right: 30px;
        }
    }

    &__head {
        padding-top: 22px;
        padding-bottom: 34px;

        @media (min-width: 768px) {
            padding-top: 30px;
            padding-bottom: 120px;
        }

        @media (min-width: 1024px) {
            padding-top: 20px;
            padding-bottom: 70px;
        }
    }

    &__row {
        @media (min-width: 768px) {
            display: flex;
        }
    }

    &__col {
        @media (min-width: 768px) {
            width: 50%;
        }

        &:first-child {
            @media (min-width: 768px) {
                padding-right: 30px;
                border-right: 1px solid rgba(255, 255, 255, 0.35);
            }
        }

        &:last-child {
            @media (min-width: 768px) {
                padding-left: 30px;
            }
        }
    }
}

.accordion {
    &__item {
        border-bottom: 1px solid rgba(255, 255, 255, 0.35);
    }

    &__head {
        padding-top: 20px;

        @media (min-width: 768px) {
            padding-top: 30px;
        }
    }

    &__title {
        font-size: 16px;
        color: $white;
        font-weight: 500;
        margin-bottom: 20px;

        @media (min-width: 768px) {
            margin-bottom: 30px;
        }
    }

    &__content {
        padding-bottom: 20px;

        @media (min-width: 768px) {
            padding-bottom: 30px;
        }
    }
}

.form {
    &__wrap {
    }

    &__field {
        margin-bottom: 10px;
    }

    &__row {
        display: flex;
        margin: 0 -5px;
    }

    &__col {
        width: 50%;
        padding: 0 5px;
    }

    &__input {
        display: block;
        width: 100%;
        color: $white;
        font-size: 14px;
        padding: 11px 20px;
        border-radius: 30px;
        transition: $base-transition;
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.6);

        &::placeholder {
            color: $white;
            font-size: 14px;
        }

        &:focus {
            border: 1px solid $main;
        }
    }

    &__footer {
        margin-top: 30px;
    }

    &__btn {
        width: 100%;
        padding: 10px 20px;
        display: block;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        color: $white;
        text-transform: uppercase;
        border-radius: 25px;
        transition: $base-transition;
        border: 1px solid $main;
        background-color: $main;

        &:hover {
            border: 1px solid $white;
            background-color: transparent;
        }
    }

    &__block {
        display: flex;
        justify-content: center;
    }

    &__link {
        color: $white;
        font-size: 14px;
        text-align: center;
        transition: $base-transition;

        &:hover {
            color: $main;
        }
    }
}
