//@import "src/styles/utils";

.footer {
  padding: 40px 0 20px;

  @media (min-width: 768px) {
    padding: 46px 0;
  }

  @media (min-width: 1024px) {
    padding: 70px 0 52px 0;
    border-top: 1px solid #ffffff;
  }

  &__blocks {
    @media (min-width: 1024px) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__socials {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 20px;
  }

  &__socIcon {
    height: 32px;
    width: 32px;
    border: 1px solid #000000;
    border-radius: 50%;

    &:hover {
      border: 1px solid #ffffff;

      circle {
        fill: none;
      }
    }
  }

  &__copyright {
    font-size: 12px;

    @media (min-width: 1024px) {
      font-size: 14px;
    }
  }
}

.container {
  text-align: center;
  padding: 0 28px;

  @media (min-width: 1024px) {
    text-align: end;
    max-width: 1300px;
    margin: 0 auto;
  }
}

.logo {
  display: block;
  height: 38px;
  width: 100px;
  margin: 0 auto 35px;

  img {
    height: 100%;
    width: 100%;
  }

  @media (min-width: 768px) {
    height: 45px;
    width: 119px;
    margin: 0 auto 40px;
  }

  @media (min-width: 1024px) {
    height: 67px;
    width: 178px;
    margin: 0;
  }
}

.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0 auto 40px;
  max-width: 360px;

  @media(min-width: 768px) {
    max-width: 404px;
    margin: 0 auto 40px;
  }

  @media(min-width: 1024px) {
    justify-content: flex-start;
    gap: 30px 40px;
    max-width: 554px;
    margin-bottom: 0;
  }

  &__link {
    text-transform: uppercase;
    font-size: 12px;
    color: #ffffff;
    white-space: nowrap;

    &:hover {
      color: #23B6E4;
    }

    @media(min-width: 768px) {
      font-size: 16px;
    }

    @media(min-width: 1024px) {
      justify-content: flex-end;
    }
  }
}


//.footer {
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
//  column-gap: 5px;
//  max-width: 1080px;
//  width: 100%;
//  height: 195px;
//  margin: 0 auto;
//  padding: 20px 20px 0;
//}
//
//.copyright {
//  font-size: 10px;
//
//  img {
//    margin-bottom: 20px;
//  }
//}
//
//.link {
//  font-size: 14px;
//  line-height: 28px;
//  color: #fff;
//  text-decoration: none;
//
//  &:hover {
//    text-decoration: underline;
//  }
//}
//
//.social {
//  display: block;
//  padding: 8px 0;
//  transition: opacity 100ms ease-in;
//
//  &:hover {
//    opacity: 0.7;
//  }
//}
//
//@include mobile {
//  .footer {
//    flex-wrap: wrap;
//    height: auto;
//  }
//
//  .copyright {
//    display: flex;
//    align-items: center;
//    justify-content: space-between;
//    width: 100%;
//    order: 1;
//  }
//
//  .block {
//    flex-basis: 45%;
//  }
//
//  .socials {
//    display: flex;
//    align-items: center;
//    column-gap: 10px;
//    margin: 20px 0;
//  }
//}
