* {
  margin: 0;
  padding: 0;
}

.categorySection {
  padding: 109px 0 40px 0;

  @media (min-width: 768px) {
    padding: 95px 0 40px;
  }

  @media (min-width: 1024px) {
    padding: 117px 0 55px 0;
  }

  &__name {
    text-align: center;
    margin-bottom: 40px;
  }
}

.container {
  width: 100%;
  max-width: 1300px;
  padding: 0 20px;
  margin: 0 auto;

  @media (min-width: 768px) {
    padding: 0 30px;
  }
}

.categoryBody {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;

  @media (min-width: 768px) {
    margin: 0 -10px;
  }
}

.category {
  position: relative;
  height: 47.5vw;
  padding: 0 5px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    height: 33.47vw;
    padding: 0 10px;
    margin-bottom: 20px;
  }

  @media (min-width: 1024px) {
    height: 263px;
    padding: 0 10px;
    margin-bottom: 20px;
  }

  &_one {
    width: 37.8%;

    @media (min-width: 768px) {
      width: 50%;
    }

    @media (min-width: 1024px) {
      width: 33.32%;
      order: 1;
      height: 293px;
    }
  }

  &_two {
    width: 62.2%;

    @media (min-width: 768px) {
      width: 50%;
    }
    @media (min-width: 1024px) {
      width: 33.32%;
      order: 2;

      & h2 {
        width: 140px;
        bottom: 80px;
      }
    }
  }

  &_three {
    width: 100%;

    @media (min-width: 1024px) {
      width: 66.67%;
      order: 5;
      height: 293px;
      position: relative;
      bottom: 30px;

      & h2 {
        font-size: 50px;
      }
    }
  }

  &_four {
    width: 62.2%;

    @media (min-width: 768px) {
      width: 50%;
    }
    @media (min-width: 1024px) {
      width: 33.32%;
      order: 4;

      & h2 {
        font-size: 26px;
      }
    }
  }

  &_five {
    width: 37.8%;

    & h2 {
      width: 100px;
    }

    @media (min-width: 768px) {
      width: 50%;

      & h2 {
        width: 80%;
      }
    }
    @media (min-width: 1024px) {
      width: 33.32%;
      order: 3;

      & h2 {
        width: 140px;
        bottom: 80px;
      }
    }
  }

  &__wrap {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  &__title {
    position: absolute;
    z-index: 1;
    bottom: 15px;
    left: 15px;
    font-size: 20px;
    word-break: break-word;

    @media (min-width: 768px) {
      bottom: 25px;
      left: 25px;
      font-size: 25px;
    }
    @media (min-width: 1024px) {
      bottom: 129px;
      left: 44px;
      font-size: 30px;
    }
  }
}