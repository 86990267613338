@import "../../styles/utils";

.block {
  max-width: 412px;
  width: 100%;
}

.text {
  @include text;
  text-shadow: $text-shadow;
}

.title {
  color: #fff;
  text-shadow: $text-shadow;
}

.container {
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 576px) {
  .canvas {
    height: auto;
  }
}
