@import "src/styles/utils";

.slider {
  position: relative;
  width: 320px;
  padding-bottom: 50px;
}

.slide {
  width: 100%;
  height: 433px;
  position: relative;

  &__img {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;

    &:first-child {
      transform: translateX(-26%);
      z-index: 2;
      animation: anim-first-img 1s linear 2.5s forwards;
    }

    &:last-child {
      transform: translateX(-77%);
      z-index: 1;
      opacity: 0;
      animation: anim-second-img 1s linear 1s forwards;
    }

    img {
      height: 100%;
      display: block;
    }
  }
}

@keyframes anim-first-img {
  0% {
    transform: translateX(-26%);
  }
  100% {
    transform: translateX(21%);
  }
}

@keyframes anim-second-img {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  padding: 9px 6px;
  cursor: pointer;
  bottom: 0;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &.disabled {
    opacity: 0.5;
  }
}

@include iPadPro {
  .slider {
    padding-bottom: 0;
  }
  .slide {
    width: 383px;
    height: 383px;
  }
  .arrow {
    display: none;
  }
}

@include mobile {
  .slider {
    width: 320px;
  }
  .slide {
    width: 320px;
    height: 320px;
  }
}
