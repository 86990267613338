@import "src/styles/utils";

.wrapper {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  overflow-y: hidden;
}

.back {
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  max-width: 65vw;
}

.img {
  position: absolute;
  left: 50%;
  width: 40vw;
  height: auto;
  object-fit: contain;
  transform: translateX(-50%);
  z-index: 1;
  opacity: 0;
}

.pipe {
  width: 100%;
  height: 100%;
}

.mini {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 25vw;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.content {
  max-width: 465px;
}

.title {
  margin-bottom: 20px;
}

.text {
  margin-bottom: 20px;
  @include text;
}

.button {
  margin-top: 40px;
}

@include mobile {
  .content {
    max-width: 100%;
    padding: 0 20px;
  }

  .img {
    position: relative;
    width: 100%;
  }
}

@media screen and (min-width: 420px) and (max-width: 1024px) {
  .container {
    margin-top: 120vh;
    margin-bottom: 10vh;
  }
}

@media screen and (min-width: 420px) and (max-width: 767px) {
  .img {
    width: 40vw;
  }
}

@media screen and (max-height: 420px) {
  .container {
    height: 340vh;
  }
  .wrapper {
    height: 130vh;
    padding-bottom: 20vh;
  }
}
