@import "src/styles/utils";

.back {
  position: sticky;
  top: 0;
  height: 120vh;
  width: 100%;

  .img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: contain;
    z-index: -1;
  }
}

.content {
  background-color: #000;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 120vh;
}

.title {
  margin-bottom: 100px;
  padding-top: 20px;
}

.blocks {
  position: relative;
  width:50vw;
  height: 300px;
}

.block {
  display: flex;
  column-gap: 16px;
  position: absolute;
  top: 0;
  width: 100%;
}

.link {
  margin-top: 40px;
}

@include iPadPro {
  .back {
    .img {
      width: 100%;
    }
  }

  .wrapper {
    justify-content: center;
  }
}

@include mobile {
  .back {
    .img {
      width: 100%;
    }
  }

  .wrapper {
    justify-content: center;
    width: 100vw;
    padding: 0 20px;
    text-align: center;
  }

  .blocks {
    width: 100%;
  }

  .block {
    display: block;
  }

  .content {
    width: 100vw;
  }

  .link {
    margin-left: 40px;
  }
}
