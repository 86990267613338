.link {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 10px;
  text-transform: uppercase;
  text-decoration: underline;
  color: #000;
  font-size: 16px;
  line-height: 22px;

  img {
    width: 20px !important;
    height: auto;
    object-fit: contain;
  }

  &.white {
    color: #fff;

    img {
      filter: brightness(0) invert(1);
    }
  }
}
