.inner {
    max-width: 86vw;
    margin: 0 auto;
}

.catalog {
    &__info {
        padding: 107px 10px 0 10px;
        display: flex;
        justify-content: space-between;
    }

    &__title {
        font-size: 60px;
        font-weight: 600;
    }

    &__text {
        margin-top: 100px;
    }

    &__about {
        width: 545px;
        margin-top: 20px;
    }

    &__img {
        display: inline-block;
        margin-right: 130px;
    }
}

.catalog {
    &__wrapper {
        display: flex;
        // grid-template-columns: 10vw 55vw;
        // grid-gap: 165px;
        justify-content: space-between;
        margin-top: 30px;
    }
    &__filter {
        width: 13vw;
    }

    &__price {
        margin-top: 40px;
    }

    &__colors {
        margin-top: 40px;
    }

    &__products {
        width: 65vw;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 180px;
    }
}

.product {
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        position: relative;
        top: -36px;
        left: -34px;
        z-index: 2;
        width: 368px;
        height: 575px;
        border: 1px solid #fff;
        background: #000;
        padding-bottom: 40px;
    }

    &:hover &__image {
        margin-top: 35px;
    }

    &:hover &__button {
        display: block;
    }
     
    &:hover &__rating {
        display: block;
    }

    &:hover .addFavoriteBtn {
        display: block;
    }

    &__wrapper {
        width: 300px;
        height: 400px;
        margin-top: 120px;
        position: relative;
    }

    &__image {
        display: block;
        width: 300px;
        height: 300px;
        margin: 0 auto;
    }

    &__title {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        margin: 0 auto;
        margin-top: 20px;
        width: 200px;
        font-weight: 600;
    }

    &__price {
        display: block;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        margin-top: 15px;
        font-size: 20px;
        font-weight: 500;
    }

    &__rating {
        display: none;
        margin: 0 auto;
        width: 100%;
        margin-top: 10px;
        text-align: center;
    }

    &__buttons {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 25px;
    }

    &__button {
        color: white;
        display: none;
        margin: 0 auto;
        width: 140px;
        width: 195px;
        height: 49px;
        text-transform: uppercase;
        background-color: #23b6e4;
        border-radius: 25px;
        padding: 15px 40px;
        font-weight: 500;
        cursor: pointer;

        a {
            color: #fff;
        }
    }

    .addFavoriteBtn{
        width: 25px;
        height: 25px;
        position: absolute;
        right: 10px;
        display: none;
    }
}

.filter__title {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
}

.colors {
    display: flex;
    margin-top: 20px;
    width: 150px;
    justify-content: space-between;
    .color {
        width: 57px;
        height: 57px;
        border-radius: 50%;
        cursor: pointer;

        &.active::before {
            position: relative;
            content: "";
            display: block;
            width: 68px;
            height: 68px;
            border: 1px solid #fff;
            border-radius: 50%;
            right: 5px;
            bottom: 5px;
        }

        &__name {
            text-align: center;
            margin-top: 15px;
        }

        &.silver {
            background: linear-gradient(180deg, #7f7a7a 0%, #545252 100%);
        }

        &.black {
            background: linear-gradient(180deg, #242120 0%, #11100f 100%);
        }
    }
}

a {
    text-decoration: none;
}






