.price-input {
    width: 100%;
    display: flex;
    margin: 22px 0 0 0;
}

.price-input .field {
    display: flex;
    width: 100%;
    align-items: center;
}

.field {
    position: relative;
}

.field span {
    position: absolute;
    top: 6px;
    left: 9px;
    font-size: 12px;
}

.field input {
    width: 0px;
    height: 0px;
    outline: none;
    opacity: 0;
    font-size: 12px;
    background-color: #161515;
    border-radius: 5px;
    text-align: center;
    -moz-appearance: textfield;
}

.label-price {
    text-align: center;
    padding-top: 5px;
    width: 84px;
    height: 24px;
    border: 1px solid #A2A1A1;
    background-color: #161515;
    border-radius: 5px;
    font-size: 12px;
    transition: all 0.2s;
}

.label-price.active {
    border-color: #23B6E4 ;
}

.input-min:focus .label-price {
    background-color: red;
}

.input-min::before {
    content: "$";
    position: absolute;
    display: block;
}


// input[type="number"]::-webkit-outer-spin-button,
// input[type="number"]::-webkit-inner-spin-button {
//     // -webkit-appearance: none;
// }

.price-input .separator {
    width: 130px;
    display: flex;
    font-size: 19px;
    align-items: center;
    justify-content: center;
}

.slider {
    height: 5px;
    position: relative;
    background: #161515;
    border-radius: 5px;
    margin-top: 41px;
}

.slider .progress {
    height: 100%;
    position: absolute;
    border-radius: 5px;
    background: #fff;
}

.range-input {
    position: relative;
    height: 6px;
    border-radius: 15px;
}

.range-input span {
    position: absolute;
    top: -35px;
    color: #fff;
}

.range-input input {
    position: absolute;
    width: 100%;
    height: 5px;
    top: -5px;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #fff;
    pointer-events: auto;
    -webkit-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

// input[type="range"]::-moz-range-thumb {
//     height: 17px;
//     width: 17px;
//     border: none;
//     border-radius: 50%;
//     background: #fff;
//     pointer-events: auto;
//     -moz-appearance: none;
//     box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
// }