@import "src/styles/utils";

.block {
  width: 280px;
}

.top {
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin-bottom: 14px;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: #262221;
  border-radius: 50%;

  img {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
}

.name {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 900;
  color: #F8F8F8;
}

.date {
  font-size: 12px;
  font-weight: 400;
  color: #ABABAB;
}

.stars {
  display: flex;
  column-gap: 5px;
  margin-bottom: 30px;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}

@include iPadPro {
  .block {
    margin: 0 auto 20px;

     p {
       text-align: left;
       font-size: 16px;
       line-height: 1.3;
     }
  }
}