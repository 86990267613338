.shopTop {
  padding: 40px 0;

  @media (min-width: 768px) {
    padding: 60px 0;
  }
  @media (min-width: 1024px) {
    padding: 55px 0 93px;
  }

  &__wrap {
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    max-width: 1300px;
    text-align: center;

    @media (min-width: 768px) {
      padding: 0 30px;
    }
  }

  &__mainTitle {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 50px;

    @media (min-width: 768px) {
      font-size: 45px;
    }
    @media (min-width: 1024px) {
      font-weight: 500;
      font-size: 60px;
      margin-bottom: 130px;
    }
  }

  &__name {
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 38px;

    @media (min-width: 768px) {
      font-size: 36px;
    }
    @media (min-width: 1024px) {
      font-size: 50px;
      text-align: left;
      margin-bottom: 60px;
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 50px;
    margin-bottom: 50px;

    @media (min-width: 1024px) {
      justify-content: flex-start;
      margin: 0 0 60px 24px;
    }
  }
}