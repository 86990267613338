.pagination {
    &__block {
        width: 100%;
        background-color: red !important;
        padding: 0 109px;
    }

    &__wrapper {
        width: 515px;
        float: right;
        display: flex;
        justify-content: space-between;
    }

    &__more {
        font-weight: 500;
        color: #23b6e4;
        text-transform: uppercase;
        cursor: pointer;
    }

    &__pages {
        width: 67px;
        display: flex;
        justify-content: space-between;
    }

    &__page {
        color: #808080;
        cursor: pointer;
        width: 20px;
        text-align: center;
        &.active {
            color: #23b6e4;
            border-bottom: 1px solid #23b6e4;
        }
    }
}
