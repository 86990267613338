.modal {
    position: absolute;
    z-index: 999;
    width: 100%;
    background: black;
    top: 82px;

    &__body {
        padding: 30px 20px;

        @media (min-width: 768px) {
            padding-bottom: 42px;
        }

        @media (min-width: 1024px) {
            padding: 44px 0 60px 0;
        }
    }

    &__title {
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 20px;

        @media (min-width: 768px) {
            font-size: 24px;
        }

        @media (min-width: 1024px) {
            font-size: 30px;
            margin-bottom: 30px;
        }
    }

    &__inputBlock {
        display: flex;
        align-items: center;
        max-width: 468px;
        margin: 0 auto;
        border: 1px solid #ffffff;
        border-radius: 20px;
        padding-right: 5px;

        @media (min-width: 1024px) {
            max-width: 600px;
            padding-right: 12px;
            border-radius: 30px;
        }
    }

    &__input {
        height: 100%;
        width: 100%;
        outline: none;
        border: none;
        border-radius: 20px;
        padding: 12px 10px 12px 30px;
        background-color: #000;
        font-size: 14px;
        color: #FFFFFF;

        @media (min-width: 768px) {
            padding: 15px 10px 15px 30px;
        }

        @media (min-width: 1024px) {
            padding: 25px 15px 25px 40px;
            font-size: 20px;
            border-radius: 30px;
        }
    }
    .btnSearch {
        height: 30px;
        width: 63px;
        background-color: #23B6E4;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 768px) {
            height: 36px;
        }

        @media (min-width: 1024px) {
            height: 49px;
            width: 94px;
        }
    }
}

