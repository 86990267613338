@import "src/styles/utils";

.intro {
  position: absolute !important;
  width: 100vw;
  height: 100vh !important;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 1;
}

.title {
  position: relative;
  max-width: 714px;
  text-align: center;
  font-size: 80px;
  font-weight: 900;
  text-shadow: $text-shadow;
  z-index: 1;
}

@include mobile {
  .title {
    max-width: 400px;
    font-size: 50px;
  }
}
