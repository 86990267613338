@import "src/styles/utils";

.container {
  display: flex;
  align-items: center;
  height: 100vh;
  background: linear-gradient(90deg, #000000 22.22%, rgba(0, 0, 0, 0) 71.03%),
  url("../../assets/images/img_2.jpg") no-repeat 100%/auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
}

.title {
  max-width: 300px;
  margin-bottom: 40px;
}

@include iPadPro {
  .container {
    background-size: cover;
    background-position: left;
  }

  .title {
    max-width: 500px;
  }
}

@include mobile {
  .container {
    background-position: 20%;
  }
}