@import "src/styles/utils";

.title {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 900;
}

@include mobile {
  .title {
    font-size: 30px;
  }
}
