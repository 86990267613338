@import "src/styles/utils";

.header {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 30px 0;
  height: 66px;
  color: $white;
  transition: all 100ms;
  z-index: 2;

  animation-name: appear;
  animation-duration: 500ms;

  &.white {
    .link img {
      filter: brightness(1) invert(1);
    }
  }
}

.logo {
  z-index: 20;
}

.controls {
  display: flex;
}

.toggle {
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid transparent;
  transition: al 0.3s;
  cursor: pointer;
  position: relative;
  z-index: 20;

  &:hover {
    border-color: $white;
  }

  span {
    width: 41px;
    height: 4px;
    background-color: $white;
    border-radius: 10px;
    transition: all 0.3s;

    & + span {
      margin-top: 5px;
    }
  }

  &.active {
    span {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      width: 30px;

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(1) {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:nth-child(3) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &.white {
    &:hover {
      border-color: #000;
    }

    span {
      background-color: #000;
    }
  }
}

.search,
.cart {
  font-size: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

@keyframes appear {
  0% {
    transform: translate(-50%, -100px);
  }

  100% {
    transform: translate(-50%, 0);
  }
}

@include iPadPro {
  .toggle {
    &:hover {
      border-color: transparent;
    }
  }
}

@include mobile {
  .header {
    padding: 20px 20px 0;
  }

  .toggle {
    width: 30px;
    height: 30px;

    span {
      width: 26px;
      height: 2px;
    }

    &.active {
      span {
        width: 20px;
        height: 1px;
      }
    }
  }
}
