@import "src/styles/utils";

.subscribe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 90px 20px;
  background-color: #fff;
}

.title {
  color: #000;
}

.form {
  display: flex;
  column-gap: 30px;
  align-items: center;
}

.input {
  max-width: 320px;
  width: 100%;
  height: 44px;
  padding: 12px 25px;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.8px;
  border: 1px solid #000;
  border-radius: 25px;

  &:focus {
    box-shadow: 0 0 0 1px rgb(147, 147, 147);
    outline: none;
    transition: .1s;
  }
}

@include iPadPro {
  .title {
    margin-bottom: 40px;
    text-align: center;
  }
}