@import "src/styles/utils";

.back {
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100%;

  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow-y: hidden;
}

.title {
  margin-bottom: 40px;
}

.blocks {
  position: relative;
  max-width: 435px;
  width: 100%;
  height: 160px;
}

.block {
  display: flex;
  gap: 16px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 160px;
  padding: 22px;
  border-radius: 25px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(39, 38, 37, 0) 100%);
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));

  img {
    width: 100px;
    height: auto;
    object-fit: contain;
  }
}

.link {
  margin-top: 40px;
}

@include iPadPro {
  .wrapper {
    justify-content: center;
  }
}

@include mobile {
  .block {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

 .back {
   .img {
     object-position: right;
   }
 }
}
