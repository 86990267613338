@import "src/styles/utils";

.blocks {
  position: relative;
  width:50vw;
  height: 300px;
}

.block {
  display: flex;
  column-gap: 16px;
  position: absolute;
  top: 0;
  width: 100%;
}

@include iPadPro {
  .blocks {
    width: 80vw;
    height: 50vh;
  }
}
