@import "src/styles/utils";

.background {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(15px);
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.block {
  position: relative;
  width: 70%;
  height: 70%;
  padding: 68px 100px;
  margin: 30px 20px;
  background-color: #fff;
  border-radius: 15px;
  animation-name: appear;
  animation-duration: 300ms;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
}

@keyframes appear {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@include mobile {
  .block {
    width: 100%;
    height: 90%;
    padding: 50px 30px;
    margin: 10px;
  }
}
