.reviews {
  text-align: center;
  padding: 90px 0;

  &__title {
    font-weight: 500;
    font-size: 50px;
    margin-bottom: 80px;
  }

  &__body {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.review {
  text-align: center;
  max-width: 281px;
  padding: 0 10px;
  background-color: #000;

  &__img {
    height: 116px;
    width: 116px;
    margin: 0 auto 10px;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__date {
    display: block;
    color: #9a9a9a;
    margin-bottom: 10px;
  }

  &__grade {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 15px;
    max-width: 128px;
  }

  &__text {
    font-weight: 400;
    line-height: 22px;
    color: #ffffff;
  }
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    & + & {
      margin-left: 10px;
    }

    [class*="active"] {
      background-color: blue;
    }
  }
}
