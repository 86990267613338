@import "src/styles/utils";
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  animation-name: appear;
  animation-duration: 300ms;
  padding: 85px 100px 20px;
}

.header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.link {
  display: inline-block;
  padding: 5px 20px;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: $white;

  & img {
    margin-right: 10px;
    filter: brightness(1) invert(1);
  }
}

.menu_inner {
  margin: auto 0;
}

.nav_wrap {
  position: relative;
  padding-bottom: 100px;
  margin-bottom: 130px;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    background-color: rgba($white, 0.36);
  }
}

.nav {
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    justify-content: center;
    position: relative;

    & + li {
      margin-top: 20px;
    }
  }
}

.like {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 1px;
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    & + & {
      margin-left: 30px;
    }
  }

  &__link {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #1b1817;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;

    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
      object-fit: cover;
    }
  }
}

.login {
  text-transform: capitalize;
  margin: auto auto 0;
  min-width: 240px;
  min-height: 49px;
}

@keyframes appear {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@include mobile {
  .wrapper {
    padding: 85px 50px 55px;
  }
  .link {
    font-size: 18px;
  }

  .nav {
    li {
      & + li {
        margin-top: 25px;
      }
    }
  }

  .menu_inner {
    margin-bottom: 100px;
  }

  .login {
    margin-top: 0;
  }

  .social {
    &__item {
      & + & {
        margin-left: 20px;
      }
    }

    &__link {
      width: 30px;
      height: 30px;
      padding: 7px;
    }
  }
}
