@import "../../../styles/utils";

.section {
  padding: 94px 0 62px;
}

.container {
  max-width: 1260px;
  padding: 0 20px;
  height: auto;
}

.title {
  font-size: 50px;
  font-weight: 500;
  line-height: 1.2;
  color: $white;
  text-align: center;
  margin-bottom: 45px;
}

.product {
  margin-bottom: 45px;

  &__row {
    display: flex;
  }

  &__circle_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    opacity: 0;
    animation: anim-circls-desktop 1s linear 4s forwards;
    order: 2;
    margin-top: -35px;
  }

  &__circle {
    &:nth-child(1) {
      margin-right: 120px;
    }
    &:nth-child(2) {
      margin-right: 50px;
    }
    &:nth-child(4) {
      margin-right: 5px;
    }
    &:nth-child(5) {
      margin-right: 50px;
    }

    & + & {
      margin-top: 40px;
    }
  }

  &__slider {
    margin: 0 auto;
    order: 1;
    display: flex;
    align-items: center;
  }

  &__tabs {
    margin-top: -10px;
    opacity: 0;
    animation: anim-tabs-desktop 1s linear 4s forwards;
  }
}

@keyframes anim-circls {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes anim-circls-desktop {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes anim-tabs {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes anim-tabs-desktop {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.footer {
  display: flex;
  align-items: center;
  width: 100%;

  &__inner {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
}

.price {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  color: $white;
  margin-right: 30px;
}

.button {
  min-width: 181px;
  justify-content: center;
}

.circle {
  display: flex;
}

@include iPadPro {
  .product {
    margin-bottom: 72px;
    &__row {
      flex-direction: column;
    }

    &__slider {
      width: 383px;
      margin-bottom: 42px;
    }

    &__circle_wrap {
      display: flex;
      flex-direction: row;
      margin-bottom: 24px;
      opacity: 0;
      animation: anim-circls 1s linear 4s forwards;
      order: 0;
      margin-top: 0;
      margin-bottom: 20px;
    }

    &__circle {
      margin-right: 0 !important;
      &:nth-child(2n + 2) {
        margin-bottom: 25px;
      }

      &:nth-child(3) {
        margin-bottom: 35px;
      }

      & + .product__circle {
        margin-top: 0;
      }
    }

    &__tabs {
      order: 2;
      margin-top: 0;
      animation: anim-tabs 1s linear 4s forwards;
    }
  }

  .title {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 60px;
  }

  .footer {
    justify-content: space-between;
    &__inner {
      flex: auto;
      margin: 0 35px 0 0;
    }
  }

  .btn_icon {
    order: 1;
  }

  .button {
    min-width: 181px;
    min-height: 50px;
    margin: 0 auto;
  }

  .price {
    font-size: 30px;
    margin-right: 35px;
  }
}

@include mobile {
  .title {
    font-size: 32px;
    margin-bottom: 40px;
  }

  .product {
    margin-bottom: 25px;

    &__circle {
      &:nth-child(3) {
        margin-bottom: 30px;
      }
    }

    &__slider {
      margin-bottom: 35px;
    }
  }

  .price {
    font-size: 20px;
  }

  .button {
    font-size: 14px;
    min-height: 36px;
    width: 138px;
  }
}
