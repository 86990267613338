.main-title {
  font-size: 20px;
}

.section-title {
  font-size: 15px;
}

.sub-title {
  font-size: 10px;
}

.small-text {
  font-size: 14px;
}

.base-text {
  font-size: 16px;
}

.big-text {
  font-size: 18px;
}