.trending {
  padding: 40px 0;

  @media (min-width: 768px) {
    padding: 60px 0 65px;
  }
  @media (min-width: 1024px) {
    padding: 95px 0 90px;
  }

  &__wrap {
    padding: 0 23px;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1300px;

    @media (min-width: 768px) {
      padding: 0 29px;
    }
  }

  &__name {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 38px;

    @media (min-width: 768px) {
      font-size: 36px;
    }
    @media (min-width: 1024px) {
      font-size: 50px;
      text-align: left;
      margin-bottom: 60px;
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 50px;
    margin-bottom: 50px;

    @media (min-width: 1024px) {
      justify-content: flex-start;
      margin: 0 0 60px 24px;
    }
  }

  &__link {
    display: none;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #23B6E4;

    @media (min-width: 768px) {
      display: block;
    }
  }
}