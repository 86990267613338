.button {
  display: flex;
  align-items: center;
  height: 44px;
  width: max-content;
  padding: 0 25px;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #23b6e4;
  border-radius: 25px;
  background-color: #23b6e4;
  transition: all 100ms ease-in;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: transparent;
    border-color: #fff;
  }

  &.transparent {
    background-color: transparent;
    border-color: #fff;
  }

  &.transparent_light {
    background-color: transparent;
    border-color: #23b6e4;
    color: #23b6e4;
  }

  &.black {
    background-color: #000;
    border-color: #000;

    &:hover {
      background-color: transparent;
      color: #000;
    }
  }

  &.white {
    color: #fff;

    &:hover {
      color: #000;
      border-color: #000;
    }
  }

  &.disabled {
    background-color: #ababab;
    border: #ababab;
    pointer-events: none;
    cursor: default;
  }
}
