@import "src/styles/utils";

.tribe {
  display: flex;
  column-gap: 90px;
  align-items: center;
  min-height: 100vh;
  padding: 20px 0;
}

.collage {
  display: grid;
  grid-template-columns: repeat(2, 20vw);
  grid-template-rows: repeat(2, 20vw);
  max-width: 1350px;
  padding-left: 40px;

  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}

.content {
  max-width: 550px;
}

.title {
  margin-bottom: 20px;
}

.text {
  margin-bottom: 20px;
  @include text;
}

.button {
  margin-top: 40px;
}

@include iPadPro {
  .tribe {
    flex-direction: column;
    row-gap: 20px;
  }

  .collage {
    padding-left: 0;
    grid-template-columns: repeat(2, 50vw);
    grid-template-rows: repeat(2, 40vw);
  }
}

@include mobile {
  .text {
    margin-bottom: 0;
    font-size: 14px;
  }

  .content {
    padding: 0 20px;
  }
}
