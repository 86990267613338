@import "src/styles/utils";

.circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover,
  &.active {
    font-weight: 500;

    .wrapper {
      border-color: #fff;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 68px;
  height: 68px;
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 50%;
  transition: all 0.3s;
}

.color {
  width: 100%;
  height: 100%;
  background-position: center;
  border-radius: 50%;
  border: none;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

@include mobile {
  .wrapper {
    width: 52px;
    height: 52px;
  }

  .circle {
    font-size: 12px;
  }
}

@include samsung {
  .wrapper {
    width: 50px;
    height: 50px;
  }
}
