@import "src/styles/utils";

.back {
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100%;

  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    background: linear-gradient(90deg, #FFFFFF 44.72%, rgba(255, 255, 255, 0.7) 53.19%, rgba(255, 255, 255, 0) 100%),
    url("../../assets/images/fifth.jpg") no-repeat 110%/70% auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .title {
    max-width: 430px;
    margin-bottom: 40px;
    color: #000;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@include iPadPro {
  .back {
    .img {
      background-size: cover;
      background-position: center;
    }

    .content {
      align-items: center;
      padding: 0 20px;
    }

    .title {
      text-align: center;
    }

    .container {
      background-size: cover;
      background-position: center;
    }
  }
}
