@import "../../../styles/utils";

.smoke {
  position: relative;
}

.canvas {
  transform: translateX(-300px) scale(0.8);
}

.wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.content {
  max-width: 412px;
  width: 100%;
}

.text {
  @include text;
  padding-bottom: 20px;
}

.button {
  margin-top: 20px;
}

@include iPadPro {
  .canvas {
    transform: translateX(-200px) scale(0.8);
  }
}

@include mobile {
  .canvas {
    transform: translateY(100px);
  }

  .content {
    max-width: 100%;
    padding: 0 20px;
  }

  .wrapper {
    padding-top: 100px;
    align-items: flex-start;
  }
}
