.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 2px dashed rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}

.text {
  font-weight: 500;
}

.hint {
  margin-top: 10px;
  color: #989898;
  font-size: 13px;
}

.button {
  margin-bottom: 15px;
}

.file {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.icon {
  margin-right: 10px;
}

.close {
  margin-left: 16px;
}

