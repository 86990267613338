@import "../../styles/utils";

.video {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  background-color: #fff;

  -webkit-mask-image: url('../../assets/mask.svg');
  mask-image: url('../../assets/mask.svg');
  -webkit-mask-position-y: -10%;
  -webkit-mask-position-x: 90%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.block {
  position: sticky;
  top: 0;
  height: 100vh;
  color: #000;
}

.wrapper {
  position: absolute;
  top: 50%;
  padding: 15px;
  transform: translateY(-50%);
}

.title {
  max-width: 270px;
  margin-bottom: 70px;
}

.text {
  max-width: 460px;
  @include text;
}

.link {
  margin-top: -30px;
}

.button {
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 10;
  transform: translateY(-50%);
  margin: 33px 35px;
  img {
    width: 36px;
    height: 36px;
    object-fit: contain;
  }
}


.pauseButton {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translateY(-50%);
 display: none;
  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    background-color: transparent;
    border-radius: 10px;
  }
  &:hover {
    display: block;
  }
}

.playButton {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translateY(-50%);


  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    background-color: #fff;
    border-radius: 10px;
  }
}

.closeButton {
  color: white;
  position: relative;
  float: right;
  right: 15px;
  top:24px;
  font-size: 14px;
  font-weight: 400;
  padding-left: 15px;
  z-index: 1;
}

@include iPadPro {
  .wrapper {
    left: 20px;


    &:first-child {
      top: 60%;

    }
  }
}

@include mobile {
  .button {
    margin-right: 10px;
    right: 0;
  }
  .closeButton {
    float: left;
    right: 0;
  }
}
