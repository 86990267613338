@import "src/styles/utils";

.slider {
  position: relative;
}

.slide {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px 5px;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  padding: 9px 6px;
  transform: translateY(-50%);
  cursor: pointer;

  &.left {
    left: -30px;
  }

  &.right {
    right: -30px;
  }

  &.disabled {
    opacity: 0.5;
  }
}

@include mobile {
  .slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0px;
  }

  .arrow {
    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}
