@import "../../../styles/utils";

.wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //height: 70vh !important;
}

.advantage {
  max-width: 412px;
  width: 100%;
}

.text {
  @include text;
  padding-bottom: 20px;
}

@include mobile {
  .wrapper {
    align-items: flex-end;
  }

  .advantage {
    max-width: 100%;
    padding: 0 20px;
    text-align: center;
    margin-bottom: 100px;
  }
}
